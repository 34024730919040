import { Injectable, Injector } from '@angular/core';
import { CoreConstants } from '../core/core.constants';
import { environment } from 'src/environments/environment';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private env: EnvService;

  constructor(private injector: Injector) {}

  init(): Promise<any> {
    return new Promise((resolve: any): any => {
      this.env = this.injector.get(EnvService);
      CoreConstants.serverUrl = this.env.serverUrl;
      if (this.env.dashboard) {
        CoreConstants.dashboard = this.env.dashboard;
      }
      resolve();
    });
  }
}
