export class CoreConstants {
  public static serverUrl: string;
  public static dashboard = '/dashboard/demo-one';
  public static currentYear = new Date().getFullYear();
  public static timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  constructor() {}
}

export interface IError {
  timeStamp: string;
  isError: boolean;
  error: any;
  status?: any;
}
