import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FullCalendarModule } from '@fullcalendar/angular';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { CoreModule } from './core/core.module';
import { AppInitService } from './app-init/app-init.service';
import { EnvServiceProvider } from './env.service.provider';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

registerLocaleData(en);

export function ConfigLoader(configService: AppInitService): any {
  return (): Promise<any> => configService.init();
}

@NgModule({
  declarations: [AppComponent, CommonLayoutComponent, FullLayoutComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    TemplateModule,
    SharedModule,
    NzBreadCrumbModule,
    NzSpinModule,
    NgChartsModule,
    NgApexchartsModule,
    FullCalendarModule,
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    EnvServiceProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [AppInitService],
      multi: true,
    },
    ThemeConstantService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
