import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CoreConstants, IError } from '../core.constants';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor() {}

  getServerUrl(endPoint: string): string {
    return CoreConstants.serverUrl + endPoint;
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  handleError(error: HttpErrorResponse) {
    const errorObj: IError = {
      timeStamp: new Date().toISOString(),
      isError: true,
      error: error.message,
    };
    return of(errorObj);
  }
}
