import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  public serverUrl = '';
  public dashboard: string;
  constructor() {}
}
