<div
  class="border-b header border-[#edf2f9] dark:border-none bg-white dark:bg-[#323540] min-h-[72px] fixed top-0 w-full z-[1000] flex"
>
  <div
    class="border-r border-[#edf2f9] dark:border-transparent logo logo-dark transition duration-200 ease-in-out items-center h-[71px]"
  >
    <a class="block text-center" href="">
      <img
        id="logo-img"
        class="ps-[30px]"
        src="assets/images/logo/logo-dark.png"
        alt="Logo"
      />
      <img
        id="logo-fold-img"
        class="p-[24px] logo-fold"
        src="assets/images/logo/logo-fold.png"
        alt="Logo"
      />
    </a>
  </div>
  <div
    class="flex flex-1 nav-wrap md:ps-[20px] md:pe-[30px] ps-[10px] pe-[15px] bg-white dark:bg-[#1b1d2a]"
  >
    <ul
      class="flex items-center mb-0 list-none nav-left ps-0 gap-x-[14px] gap-y-[9px]"
    >
      <li class="desktop-toggle">
        <a
          class="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary/10 hover:text-primary group transition duration-200 ease-in-out text-[#525768] dark:text-white/60 dark:hover:bg-white/10"
          (click)="toggleFold()"
        >
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&.is-folded>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg"
          />
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-folded>svg]:block"
            src="assets/images/svg/align-left.svg"
          />
        </a>
      </li>
      <li class="mobile-toggle">
        <a
          class="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary/10 hover:text-primary group transition duration-200 ease-in-out text-[#525768] dark:text-white/60 dark:hover:bg-white/10"
          (click)="toggleExpand()"
        >
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&.is-expand>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg"
          />
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-expand>svg]:block"
            src="assets/images/svg/align-left.svg"
          />
        </a>
      </li>
      <!-- <li>
        <a class="inline-flex items-center bg-normalBG hover:bg-primary/10 hover:text-primary dark:bg-[#282b37] dark:text-white/60 min-h-[34px] max-sm:w-[34px] max-sm:justify-center px-4 max-sm:px-0 rounded-2xl gap-[8px] dark:hover:bg-white/60 group dark:hover:text-dark transition duration-200 capitalize" (click)="quickViewToggle()">
          <i nz-icon nzType="edit" theme="outline"></i>
          <span class="text-sm font-medium text-body group-hover:text-primary dark:text-white/60 dark:group-hover:text-current max-sm:hidden">
            customize...
          </span>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible"
          nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <ng-container *nzDrawerContent>
            <app-quick-view></app-quick-view>
          </ng-container>
        </nz-drawer>
      </li> -->
    </ul>
    <!-- Top Menu -->
    <app-menu></app-menu>
    <!-- desktop -->
    <div class="items-center hidden ms-auto xl:flex">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul
        class="flex items-center justify-end flex-auto mb-0 list-none ps-0 nav-right sm:gap-x-[25px] max-sm:gap-x-[15px] gap-y-[15px]"
      >
        <li>
          <a
            class="flex items-center"
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="search"
            nzPlacement="bottomRight"
            #suffixIconSearch
          >
            <svg-icon src="assets/images/svg/search.svg"></svg-icon>
          </a>
          <nz-dropdown-menu #search="nzDropdownMenu">
            <nz-input-group
              class="dark:bg-[#282936] p-1.5 h-[48px] px-[20px] dark:shadow-none border-1 border-regular dark:border-white/10 rounded-6"
              [nzSuffix]="suffixIconSearch"
            >
              <input
                class="capitalize bg-white placeholder:text-theme-light text-theme-gray dark:text-white/[.87] dark:bg-transparent text-ellipsis"
                type="text"
                nz-input
                placeholder="search here"
              />
            </nz-input-group>
          </nz-dropdown-menu>
        </li>
        <li>
          <a
            class="flex items-center"
            nz-dropdown
            [nzDropdownMenu]="popSettings"
          >
            <nz-badge>
              <svg-icon src="assets/images/svg/settings.svg"></svg-icon>
            </nz-badge>
          </a>
          <nz-dropdown-menu #popSettings="nzDropdownMenu">
            <perfect-scrollbar
              class="lg:w-[700px] md:w-[300px] max-md:w-[230px] px-[15px] py-[12px] bg-white dark:bg-[#1b1e2b] shadow-[0_2px_8px_rgba(0,0,0,.15)] dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4"
            >
              <ul
                class="flex flex-wrap items-center md:[&>li]:flex-[50%] max-md:[&>li]:flex-[100%] max-h-[251px]"
              >
                <li *ngFor="let settings of appSettings">
                  <figure
                    class="flex items-start px-4 py-5 mb-0 hover:shadow-action dark:hover:shadow-[0_5px_30px_rgba(1,4,19,.60)]"
                  >
                    <img
                      class="h-fit me-4"
                      src="assets/images/others/settings/{{
                        settings.source
                      }}.png"
                      alt="{{ settings.source }}"
                    />
                    <figcaption>
                      <h1
                        class="mb-0.5 -mt-1 text-[15px] font-medium capitalize text-dark dark:text-white/[.87]"
                      >
                        {{ settings.label }}
                      </h1>
                      <p class="mb-0 text-theme-gray dark:text-white/60">
                        {{ settings.des }}
                      </p>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </perfect-scrollbar>
          </nz-dropdown-menu>
        </li>
        <li>
          <span
            class="flex items-center cursor-pointer text-light whitespace-nowrap"
            nz-dropdown
            [nzDropdownMenu]="profile"
          >
            <nz-avatar
              [nzIcon]="'user'"
              nzSrc="assets/images/avatars/thumbs.png"
            ></nz-avatar>
          </span>
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <div
              class="min-w-[310px] max-sm:min-w-full pt-4 px-[15px] py-[12px] bg-white dark:bg-[#1b1e2b] shadow-[0_2px_8px_rgba(0,0,0,.15)] dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4"
            >
              <figure
                class="flex items-center text-sm rounded-[8px] bg-section dark:bg-white/10 py-[20px] px-[25px] mb-[12px]"
              >
                <nz-avatar
                  [nzIcon]="'user'"
                  nzSrc="assets/images/avatars/thumbs.png"
                  class="me-4"
                ></nz-avatar>
                <figcaption>
                  <h1 class="text-dark dark:text-white/[.87] mb-0.5 text-sm">
                    Md. Rafiq
                  </h1>
                  <p class="mb-0 text-xs text-body dark:text-white/60">
                    UI Expert
                  </p>
                </figcaption>
              </figure>
              <ul nz-menu class="mb-0 bg-transparent shadow-none">
                <li
                  class="p-0 dark:hover:text-white hover:bg-primary/10 dark:hover:bg-white/10 rounded-4"
                  nz-menu-item
                  *ngFor="let authorMenu of appAuthorMenu"
                >
                  <button
                    class="inline-flex items-center text-light dark:text-white/60 hover:text-primary hover:ps-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
                  >
                    <svg-icon
                      class="h-fit me-4 [&>svg]:w-[18px] [&>svg]:h-[18px]"
                      src="assets/images/svg/feather/{{ authorMenu.icon }}.svg"
                      alt="{{ authorMenu.icon }}"
                    ></svg-icon>
                    {{ authorMenu.label }}
                  </button>
                </li>
              </ul>
              <a
                class="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] mx-[-15px] mb-[-15px] rounded-b-6"
                (click)="signOut()"
              >
                <i class="facebook"></i> Sign Out</a
              >
            </div>
          </nz-dropdown-menu>
        </li>
      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a
      class="items-center hidden max-xl:flex text-light text-[18px] dark:text-white/[.87] ms-auto"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu"
    >
      <span nz-icon nzType="more" nzTheme="outline"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div
        class="bg-white dark:bg-[#1b1c29] px-[20px] py-[6px] rounded-6 hidden max-xl:block"
      >
        <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->
  </div>
</div>
