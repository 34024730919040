import { TopMenuInterface } from '../../interfaces/top-menu';

export const ROUTES: TopMenuInterface[] = [
  {
    megaClass: '',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    path: '/dashboard/demo-one',
    icon: 'appstore-add',
    submenu: [],
  },
];
