import { EnvService } from './env.service';

export const getText = (cipher: string, key: number, encode: boolean = false, phrase?: string): string => {
  const alphabet = phrase ? phrase : 'lwkxzdinufoqgpvehrjtscyamb';
  const fullAlphabet = alphabet + alphabet + alphabet;

  const cipherText = cipher;
  let cipherOffset = key;
  cipherOffset = cipherOffset % alphabet.length;
  let plainText = '';

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < cipherText.length; i++) {
    let letter = cipherText[i];
    const upper = letter === letter.toUpperCase();
    if (letter) {
      letter = letter.toLowerCase();
    }

    let index = alphabet.indexOf(letter);
    if (index === -1) {
      plainText += letter;
    } else {
      if (encode) {
        index = index + cipherOffset + alphabet.length;
      } else {
        index = index - cipherOffset + alphabet.length;
      }
      let nextLetter = fullAlphabet[index];
      if (upper) {
        nextLetter = nextLetter.toUpperCase();
      }
      plainText += nextLetter;
    }
  }
  return plainText;
};

export const EnvServiceFactory = () => {
  // Create EnvService
  const env = new EnvService();

  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__irz'] || {};
  // First decrypt the base64 string
  const decipher = getText(browserWindowEnv, 16);
  // convert to base64 to string
  let configObj = {};
  try {
    configObj = JSON.parse(atob(decipher));
  } catch (e) {
    console.error('please check that config is valid Json object');
  }

  // Assign environment variables from browser window to env
  for (const key in configObj) {
    if (configObj.hasOwnProperty(key)) {
      env[key] = configObj[key];
    }
  }
  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
