import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
  {
    path: '/dashboard/demo-one',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    submenu: [],
  },
  {
    path: '/dashboard/demo-three',
    title: 'Cases',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'paper-clip',
    submenu: [],
  },
  {
    path: '/dashboard/demo-two',
    title: 'Active Investigation',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'clipboard',
    submenu: [],
  },
  {
    path: '/dashboard/demo-four',
    title: 'Hunt',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'activity',
    submenu: [],
  },
  {
    path: '/dashboard/demo-five',
    title: 'User Explorer',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'users',
    submenu: [],
  },
  {
    path: '/dashboard/demo-six',
    title: 'Host Explorer',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'computer-device',
    submenu: [],
  },
  {
    path: '/dashboard/demo-seven',
    title: 'Process Explorer',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'table',
    submenu: [],
  },
  {
    path: '/dashboard/demo-eight',
    title: 'Notifications',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'message-square',
    submenu: [],
  },
  {
    path: '',
    title: 'Setting',
    iconType: 'svg',
    iconTheme: 'outline',
    icon: 'settings',
    submenu: [
      {
        path: '/dashboard/demo-nine',
        title: 'Profile',
        iconType: '',
        iconTheme: 'outline',
        icon: '',
        submenu: [],
      },
    ],
  },
];
