import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
  //Dashboard
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then((m) => m.DashboardModule),
  },

  //Pages
  {
    path: 'pages',
    data: {
      title: 'Pages ',
    },
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () => import('../../feature-modules/feature-modules.module').then((m) => m.PagesModule),
      },
    ],
  },
];
